
var base_urn = '/assets';

var Keys = {
	38: 'up',
	37: 'left',
	39: 'right',
	40: 'down'
};

var isPopState = !1,
	isSetSizes = !1,
	isThumbsLoad = !1,
	isMobileMarking = !1,
	getSiblingsTimeout = !1,
	deferredFame = $.Deferred(),
	deferredWelcome = $.Deferred();

function isMobile() {
	return (device.desktop() && ($(window).innerWidth() > 1200)) ? false : true;
}

function mobileMarkingOn() {
	if (!isMobileMarking) {
		isMobileMarking = true;
		$('nav.lang').prependTo('nav.main');
		$(window).on('resize.mobile', function(e) {
			var w_height = $(this).outerHeight();
			$('.shape, .welcome, .gallery, .actions .item, .news .item').css({height: w_height});
			
			if (!isSetSizes) {
				isSetSizes = true;
				setTimeout(function() {
					var y = $('.screen.active').offset().top;
					$('html, body').animate({scrollTop: y}, 0, function() {
						TweenMax.to('.preloader', 0.6, {
							opacity: 0,
							display: 'none'
						});
					});
				}, 0);
			}
		}).trigger('resize.mobile');
		var $btnMenuClone = $('.btn-menu').eq(0).clone(),
			$newsBaseClone = $('.news .some .base').eq(0).clone();
		$newsBaseClone.find('.item:first').remove();
		$newsBaseClone.append($btnMenuClone).appendTo('.news .some .scene');
	}
}
function mobileMarkingOff() {
	if (isMobileMarking) {
		isMobileMarking = false;
		$(window).off('resize:mobile');
		$('.news .feed + .base').remove();
		$('nav.lang').prependTo('.screen:first');
		$('.shape, .welcome, .gallery, .actions .item, .news .item').css({height: ''});
	}
}

/*[fn] [:]*/

/*[page] [:]*/
function getDataPage(name) {
	var $page = $('.screen[data-page="'+name+'"]'),
		$first = $('.screen:first'),
		title = $page.data('title'),
		part = $first.data('part'),
		page = $page.data('page'),
		urn = $page.data('urn'),
		theme = getTheme(part);
	
	return {
		urn: urn,
		part: part,
		page: page,
		title: title,
		theme: theme
	};
}
function getSiblingsPages() {
	var promise = $.when(),
		$first = $('.screen:first'),
		$this = $('.screen.active'),
		part = $first.data('part'),
		section = '[data-part="'+part+'"]',
		$screens = $('.screen').filter(section);
	
	$('.screen').removeClass('hidden').not(section).addClass('hidden');
	
	$screens.each(function(index, element) {
		var $this = $(this),
			urn = $this.data('urn'),
			name = $this.data('page'),
			page = $('body').data('page'),
			$screen = $('.screen[data-page="'+page+'"]');
		
		if (!$this.hasClass('loaded')) {
			promise = promise.then(function() {
				return $.ajax({
					url: urn,
					type: 'get',
					dataType: 'html',
					error: function() {},
					success: function(data) {
						$this.addClass('loaded').find('.output').html(data);
					}
				});
			});
		}
	});
	
	promise.then(function() {
		if ($('.fame').length) {
			var x = $('.fame .season .pin.active').offset().left;
			$('.fame .season .wrap').animate({scrollLeft: x}, 200);
		}
		
		enquire.register('screen and (max-width:1200px)', {
			match: function() {
				mobileMarkingOn();
			},
			unmatch: function() {
				mobileMarkingOff();
			}
		});
		
		createWelcomeGallery();
		createFameGallery();
		validateForm();
		
		if (!isMobile()) {
			TweenMax.to('.preloader', 0.6, {
				opacity: 0,
				display: 'none'
			});
		}
	});
}
function getPage(urn, callback) {
	$.ajax({
		url: urn,
		type: 'get',
		dataType: 'html',
		error: function() {},
		success: function(data) {
			callback(data);
			$(window).trigger('resize');
		}
	});
}
/*[page] [;]*/

/*[other] [:]*/
function getTheme(part) {
	switch(part) {
		case 'biathlon':
			theme = 'theme-1';
				break;
		case 'life':
			theme = 'theme-2';
				break;
		case 'projects':
			theme = 'theme-3';
				break;
		case 'partners':
			theme = 'theme-4';
				break;
	} return theme;
}
function formReset(name) {
	$form = $(name);
	$form.validate().resetForm();
	$form.find('input, textarea').val('');
	$form.find('.error, .valid').removeClass('error valid');
}
/*[other] [;]*/

/*[state] [:]*/
function changeState(name, action, meta) {
	var data = getDataPage(name),
		theme = data.theme,
		title = data.title,
		urn = data.urn;
	
	if (meta) {
		title = getMetaTitle();
	}
	
	setTitle(title);
	TweenMax.set('body', {
		className: theme
	});
	
	if (!isPopState) {
		if (action === 'push') {
			history.pushState(data, title, urn);
			//google analytics
			ga('send', 'pageview', urn);
		} else {
			history.replaceState(data, title, urn);
		}
	} isPopState = false;
	dataLayer.push({
		'virtualPageURL': urn,
		'virtualPageTitle': title,
		'event': 'VirtualPageview'
	});
}
/*[state] [;]*/

/*[title] [:]*/
function setTitle(title) {
	if ($('title').text() !== title) {
		TweenMax.set('title', {
			text: title
		});
	}
}
function getMetaTitle() {
	var title = !1;
	if ($('.screen').length) {
		title = $('.screen.active .meta-title').data('value');
	} else {
		title = $('.article .meta-title').data('value');
	} return title;
}
/*[title] [;]*/

/*[screen] [:]*/
var isAnimateScreen = false;
function animateScreen($next) {
	if (!isAnimateScreen) {
		var y = !1,
			axis = !1,
			$this = $('.screen.active'),
			this_index = $this.index(),
			next_index = $next.index();
		
		axis = (this_index > next_index) ? 'prev' : 'next';
		y = (axis === 'prev') ? -100 : 100;
		
		new TimelineMax({
			onStart: function() {
				isAnimateScreen = true;
			},
			onUpdate: function() {
				$(window).trigger('resize');
			},
			onComplete: function() {
				isAnimateScreen = false;
				
				$next.addClass('active').siblings().removeClass('active');
				TweenMax.set('.screen', {clearProps: 'all'});
			}
		}).add([
			TweenMax.to($this, 0.5, {
				display: 'none',
				ease: Sine.easeInOut
			}),
			TweenMax.fromTo($next, 0.5, {
				bottom: 0,
				zIndex: 150,
				yPercent: y,
			}, {
				yPercent: 0,
				display: 'block',
				ease: Sine.easeInOut
			})
		]);
	}
}
function switchScreen(part, page, axis) {
	if (!isMobile()) {
		var $next = !1,
			$this = $('.screen.active'),
			$first = $('.screen:first');

		part = (part) ? part : $first.data('part');

		if (page) {
			$next = $('.screen[data-page="'+page+'"]');
		} else {
			$next = (axis === 'prev') ? 
						$this.prevAll('[data-part="'+part+'"]').first() : 
							$this.nextAll('[data-part="'+part+'"]').first();
		}

		if ($next.length) {
			var pg = $next.data('page');
			changeState(pg, 'push');
			animateScreen($next);

			$('nav.main .column .link').removeClass('active');
			$('nav.main .column[data-section="'+part+'"] .link[data-screen="'+page+'"]').addClass('active');
		}
	}
}
/*[screen] [;]*/

/*[fn] [;]*/

/*[menu] [:]*/
function switchMenu(action) {
	if (action === 'show') {
		$('body, .screen').css({'overflow': 'hidden'});
		TweenMax.to('nav.main', 0.6, {
			right: 0,
			ease: Sine.easeInOut,
			onComplete: function() {
				$('nav.main').addClass('open').removeAttr('style');
			}
		});
	} else {
		$('body, .screen').css({'overflow': ''});
		TweenMax.to('nav.main', 0.6, {
			right: '100%',
			ease: Sine.easeInOut,
			onComplete: function() {
				$('nav.main').removeClass('open').removeAttr('style');
			}
		});
	}
}

$(document).on('keydown', function(e) {
	if ($('nav.main').hasClass('open')) {
		if (e.which === 27) {
			e.preventDefault();
			switchMenu('hide');
		}
	}
});

$(document).on('click', '.btn-menu', function(e) {
	switchMenu('show'); return false;
});

$(document).on('click', 'nav.main .link', function(e) {
	switchMenu('hide');
	if ($('body').hasClass('inner')) {
		return true;
	} else {
		var $this = $(this),
			$group = $this.parents('.column'),
			part = $group.data('section'),
			page = $this.data('screen'),
			index = $group.index();
		if ($this.parent('li').is(':first')) {
			$('.welcome .scene').flickity('select', index);
			return false;
		}
	}
});

$(document).on('click', 'nav.main .close', function(e) {
	switchMenu('hide'); return false;
});

(function() {
	var $this = $('.screen.active'),
		part = $this.data('part'),
		page = $this.data('page');
	$('nav.main .column .link').removeClass('active');
	$('nav.main .column[data-section="'+part+'"] .link[data-screen="'+page+'"]').addClass('active');
}());
/*[menu] [;]*/

if ($('body').hasClass('inner')) {
	
	/*[404] [:]*/
	var videoCover = function() {
		var v_scale,
			offset = 250,
			v_minWidth = 960, 
			v_width, v_height,
			v_scaleH, v_scaleV,
			v_initWidth = 1920,
			v_initHeight = 960,
			w_width = $(window).width() + offset,
			w_height = $(window).height() + offset,
			$video = $('.not-found video'),
			$scene = $('.not-found .canvas');
		
		v_scaleH = w_width / v_initWidth;
		v_scaleV = w_height / v_initHeight;
		v_scale  = v_scaleH > v_scaleV ? v_scaleH : v_scaleV;
	
		if (v_scale * v_initWidth < v_minWidth) {
			v_scale = v_minWidth / v_initWidth;
		}
		
		v_width = v_scale * v_initWidth;
		v_height = v_scale * v_initHeight;
		
		$scene.css({
			width: w_width,
			height: w_height,
			top: -(offset / 2),
			left: -(offset / 2)
		});
		
		$video.css({
			width: v_width,
			height: v_height
		});
		
		$scene.scrollLeft((v_width - w_width) / 2);
		$scene.scrollTop((v_height - w_height) / 2);
	};
	$(window).on('resize', function(e) {
		videoCover();
	}).trigger('resize');
	/*[404] [;]*/
	
	/*[photos] [:]*/
	var toggleGallery = function(action) {
		if (action === 'open') {
			TweenMax.set('body', {
				overflow: 'hidden'
			});
			TweenMax.to('.photos', 0.6, {
				opacity: 1,
				display: 'block',
				ease: Sine.easeOut
			});
		} else {
			TweenMax.set('body', {
				clearProps: 'overflow'
			});
			TweenMax.to('.photos', 0.6, {
				opacity: 0,
				display: 'none',
				ease: Sine.easeOut
			});
		}
	};
	
	var switchGallery = function(course, index, isPopState) {
		var hash, path, urn,
			$this = $('.photos .active');
		
		if (course) {
			if (course === 'prev') {
				$item = $this.prev();
				$item = $item.length ? $item : $('.photos .item:last');
				$item.addClass('active').siblings().removeClass('active');
			} else 
			if (course === 'next') {
				$item = $this.next();
				$item = $item.length ? $item : $('.photos .item:first');
			}
		} else {
			$item = $('.photos .item').eq(index);
		}
		
		var name = $item.data('name');
		
		if (name) {
			TweenMax.to('.photos .controls .name', 0.6, {
				text: name,
				autoAlpha: 1
			});
		} else {
			TweenMax.to('.photos .controls .name', 0.6, {
				autoAlpha: 0
			});
		}
		
		hash = '#' + $item.attr('id');
		path = location.pathname;
		urn = path + hash;
		
		$item.addClass('active').siblings().removeClass('active');
		
		if (!isPopState) {
			history.pushState(null, null, urn);
		}
		
		$(window).trigger('resize');
	};
	
	var hashSwitchGallery = function(isPopState) {
		if (!isMobile()) {
			var $slide, index,
				hash = location.hash;
			if (hash) {
				$slide = $(hash);
				if ($slide.parents('.photos').length) {
					index = $slide.index();
					switchGallery(false, index, isPopState);
					toggleGallery('open');
				}
			}
		}
	};
	
	hashSwitchGallery();
	
	$(document).on('keyup', function(e) {
		if (!isMobile() && $('.photos').hasClass('open')) {
			if (e.which === 38 || e.which === 40) {
				e.preventDefault();
				if (e.which === 38) {
					switchGallery('prev');
				} else 
				if (e.which === 40) {
					switchGallery('next');
				}
			}
		}
	});
	
	$(document).on('click', '.photos .prev', function(e) {
		switchGallery('prev'); return false;
	});
	$(document).on('click', '.photos .close', function(e) {
		history.pushState(null, null, location.pathname);
		toggleGallery('close');
		return false;
	});
	$(document).on('click', '.photos .next, .photos .active', function(e) {
		if (!isMobile()) {
			switchGallery('next');
		} return false;
	});
	/*[photos] [;]*/
	
	/*[article] [:]*/
	$(window).on('scroll', function(e) {
		if (!isMobile()) {
			var top = $(window).scrollTop(),
				y = -(top * 0.2);
			TweenMax.set('.article .images', {y: y});
		} else {
			$('.article .images').removeAttr('style');
		}
	}).trigger('scroll');
	
	$(window).on('popstate', function(e) {
		var urn = location.href,
			hash = location.hash;
		
		if (hash) {
			hashSwitchGallery(true);
		} else {
			getPage(urn, function(data) {
				$('.article').html(data);
			});
		}
	});
	
	$(document).on('click', '.article .manage a', function(e) {
		var urn = $(this).attr('href');
		getPage(urn, function(data) {
			$('.article').html(data);
			var title = getMetaTitle();
			history.pushState(null, title, urn);
			dataLayer.push({
				'virtualPageURL': urn,
				'virtualPageTitle': title,
				'event': 'VirtualPageview'
			});
		}); return false;
	});
	
	$(document).on('click', '.article .images .more', function(e) {
		switchGallery(false, 0);
		toggleGallery('open');
		return false;
	});
	
	$(document).on('click', '.article .images .item', function(e) {
		var $this = $(this),
			index = $this.index();
		switchGallery(false, index);
		toggleGallery('open');
		return false;
	});
	/*[article] [;]*/
	
	$(window).on('load', function(e) {
		TweenMax.to('.preloader', 0.6, {
			opacity: 0,
			display: 'none'
		});
	});
	
} else {

	/*[pages] [:]*/
	
	/*[fame] [:]*/
	var $fame = !1;
	var createFameGallery = function() {
		if ($('.fame').length) {
			if ($fame) $fame.destroy();
			$fame = new IScroll('.fame .graph', {
				scrollX: true,
				scrollY: false,
				bounceTime: 800,
				deceleration: 0.006,
				useTransition: false,
				HWCompositing: false,
				keyBindings: {
					left: 37,
					right: 39
				}
			});
			
			$fame.on('beforeScrollStart', function() {
				$fame.refresh();
			});
			
			if ($('.fame .item').hasClass('active')) {
				$fame.scrollToElement('.fame .item.active', 0);
			}
		}
	};
	
	if ($('.fame').length) {
		createFameGallery();
	}
	
	$(document).on('click', '.fame a', function(e) {
		var $this = $(this),
			$fame = $('.fame'),
			isTweenComplete = !1,
			urn = $this.attr('href'),
			href = location.pathname + location.search,
			urn_gallery = urn.replace(/fame/, 'gallery');
		
		function fnAction() {
			if ($this.hasClass('more')) {
				if ($fame.hasClass('open')) {
					href += '#races';
				}
				$this.attr('href', href);
				$fame.toggleClass('open');
			}
			
			if (!$this.hasClass('move')) {
				$('.screen[data-page="fame"]').attr({'data-urn': urn}).data({'urn': urn});
				$('.screen[data-page="gallery"]').attr({'data-urn': urn_gallery}).data({'urn': urn_gallery});
				
				if (!$this.hasClass('more') && !$this.hasClass('active')) {
					getPage(urn, function(data) {
						$('.screen[data-page="fame"] .output').html(data);
						var x = $('.fame .season .pin.active').offset().left;
						$('.fame .season .wrap').animate({scrollLeft: x}, 200);
						changeState('fame', 'push', true);
						createFameGallery();
					});
					getPage(urn_gallery, function(data) {
						$('.screen[data-page="gallery"] .output').html(data);
					});
				}
			}
		}
		
		if (isMobile()) {
			var y = $fame.offset().top;
			$('html, body').animate({
				scrollTop: y
			},{
				duration: 600,
				complete: function() {
					if (!isTweenComplete) {
						isTweenComplete = true;
						fnAction();
					}
				}
			});
		} else {
			fnAction();
		} return false;
	});
	
	if ($('.screen[data-page="fame"]').hasClass('active')) {
		var href = location.pathname + location.search;
		if (location.hash === '#races') {
			$('.fame').addClass('open');
			$('.fame .more').attr('href', href);
		}
	}
	/*[fame] [;]*/
	
	/*[news] [:]*/
	var newsFilling = function($link) {
		if (!$link.hasClass('loaded')) {
			var urn = $link.attr('href');
			$('.news .link:not([href="'+urn+'"])').removeClass('active');
			
			$.ajax({
				url: urn,
				type: 'get',
				dataType: 'html',
				success: function(data) {
					$link.addClass('loaded');
					$('.news .full .scene').html(data).trigger('focus');
					if (isMobile()) {
						$('.news .item').css({
							height: $(window).height()
						});
					}
				}
			});
		}
	};
	
	$(document).on('click', '.news .more', function(e) {
		var $page = $('.news'),
			$this = $('.news .menu .active'),
			theme = 'theme-' + ($this.parent().index() + 1);
		
		if ($page.hasClass('open')) {
			$('body, .screen').css({'overflow': ''});
			TweenMax.set($page, {className: 'news'});
		} else {
			newsFilling($this);
			TweenMax.set($page, {className: 'news open ' + theme});
			if (!isMobile()) {
				$('body, .screen').css({'overflow': 'hidden'});
			}
		} return false;
	});
	
	$(document).on('click', '.news .menu .link', function(e) {
		var $this = $(this),
			theme = 'theme-' + ($this.parent().index() + 1);
		TweenMax.set('.news', {className: 'news open ' + theme});
		$('.news .menu .link').removeClass('loaded active');
		$this.addClass('active');
		newsFilling($this);
		return false;
	});
	/*[news] [;]*/
	
	/*[story] [:]*/
	$(document).on('click', '.story a', function(e) {
		var $this = $(this),
			urn = $this.attr('href'),
			$screen = $('.screen[data-page="story"]');
		
		if (!$this.hasClass('move')) {
			$screen.data('urn', urn);
			getPage(urn, function(data) {
				$('.screen[data-page="story"] .output').html(data);
				changeState('story', 'push', true);
			});
		} return false;
	});
	/*[story] [;]*/
	
	/*[screen] [:]*/
	(function() {
		var timeout = 0,
			wheeling = 0;
		
		function isScreenScrollStop(event, callback) {
			if (!$('.news, .fame, .actions, .gallery').hasClass('open')) {
				var $screen = $('.screen.active'),
					page = $screen.data('page'),
					factor = $screen.find('.news.open .scene .group').length || 
							 $screen.find('.actions.open .scene .group').length || 1;
				
				var $content = $screen.find('.output > div'),
					h_content = $content.outerHeight() * factor,
					h_screen = $screen.outerHeight(),
					deltaFactor = event.deltaFactor,
					top = $screen.scrollTop(),
					bot = h_screen + top,
					delta = event.deltaY;
				
				if (h_screen >= h_content || bot >= h_content || (delta > 0 && top === 0)) {
					deltaFactor += deltaFactor;
					if (deltaFactor > event.deltaFactor) {
						deltaFactor = event.deltaFactor;
						callback();
					}
				}
			}
		}
		
		$(document).on('mousewheel', function(event) {
			if (!$('nav.main').hasClass('open')) {
				var delta = event.deltaY;

				if (delta > 0 && delta <= 100) {
					delta += 99;
				}

				if (delta < 0 && delta >= -100) {
					delta -= 99;
				}

				wheeling += delta;

				if (wheeling >= 400 || wheeling <= -400) {
					clearTimeout(timeout);
					isScreenScrollStop(event, function() {
						timeout = setTimeout(function() {
							if (event.deltaY >= 1) {
								switchScreen(!1, !1, 'prev');
							} else {
								switchScreen(!1, !1, 'next');
							} wheeling = 0;
						}, 400);
					});
				}
			}
		});
		
		$(document).on('click', '.screen .move', function(e) {
			if (!isMobile()) {
				switchScreen(!1, !1, 'next');
				return false;
			} else {
				var $this = $(this).parents('.screen'),
					$next = $this.next('.loaded'),
					y = $next.offset().top;
				$('html, body').animate({scrollTop: y}, 600);
			}
		});
	}());
	/*[screen] [;]*/
	
	/*[welcome] [:]*/
	var createWelcomeGallery = function() {
		if ($('.welcome').length) {
			var $scene = $('.welcome .scene');
			if (!$scene.hasClass('flickity-enabled')) {
				$scene.flickity({
					pageDots: false,
					wrapAround: true,
					accessibility: false,
					setGallerySize: false,
					prevNextButtons: false,
					draggable: isMobile() ? false : true,
					initialIndex: $('.welcome .active').index()
				})
				.on('cellSelect', function(e) {
					var $this = $('.welcome .is-selected'),
						$switch = $('.welcome .next .name'),
						title = $this.data('title'),
						part = $this.data('part'),
						page = $this.data('page'),
						urn = $this.data('urn'),
						theme = getTheme(part),
						$next = $this.next(),
						$menu = $('nav.main .column[data-section="'+part+'"]'),
						urn_contacts = $menu.find('.link[data-screen="contacts"]').attr('href'),
						urn_gallery = $menu.find('.link[data-screen="gallery"]').attr('href'),
						urn_news = $menu.find('.link[data-screen="news"]').attr('href');
					
					$next = $next.length ? $next : $('.welcome .slide:first');
					
					var next_title = $next.data('title');
					
					$('.screen:first').attr({
						'data-urn': urn,
						'data-part': part,
						'data-page': page,
						'data-title': title
					}).data({
						'urn': urn,
						'part': part,
						'page': page,
						'title': title
					});
					
					if (urn_news) {
						$('.screen[data-page="news"]').removeClass('loaded').attr({
							'data-part': part,
							'data-urn': urn_news
						}).data({
							'urn': urn_news
						});
					}
					
					if (urn_gallery) {
						$('.screen[data-page="gallery"]').removeClass('loaded').attr({
							'data-part': part,
							'data-urn': urn_gallery
						}).data({
							'urn': urn_gallery
						});
					}
					
					if (urn_contacts) {
						$('.screen[data-page="contacts"]').removeClass('loaded').attr({
							'data-part': part,
							'data-urn': urn_contacts
						}).data({
							'urn': urn_contacts
						});
					}
					
					$('.news .menu .link').removeClass('active').filter('[data-theme="'+theme+'"]').addClass('active');
					$('.welcome .next').attr('href', $next.data('urn'));
					
					if ($switch.text() !== next_title) {
						TweenMax.set($switch, {
							text: next_title
						});
					}
					
					changeState(page, 'push');
					getSiblingsPages();
				});
				
				$(document).on('keydown', function(e) {
					var $welcome = $('.welcome .scene');
					if ($('.screen:first').hasClass('active')) {
						if (e.which in Keys) {
							e.preventDefault();
							if (e.which === 37) {
								$welcome.flickity('previous', true);
							} else 
							if (e.which === 39) {
								$welcome.flickity('next', true);
							}
						}
					}
				});
				
				$(document).on('click', '.welcome .next', function(e) {
					if (!isMobile()) {
						var $welcome = $('.welcome .scene');
						$welcome.flickity('next', true);
						return false;
					}
				});
			}
		}
	};
	/*[welcome] [;]*/
	
	/*[actions] [:]*/
	var actionsFilling = function($link) {
		if (!$link.hasClass('loaded')) {
			var urn = $link.attr('href');
			$('.actions .link:not([href="'+urn+'"])').removeClass('active');
			
			$.ajax({
				url: urn,
				type: 'get',
				dataType: 'html',
				success: function(data) {
					$link.addClass('loaded');
					$('.actions .full .scene').html(data).trigger('focus');
					if (isMobile()) {
						$('.actions .item').css({
							height: $(window).height()
						});
					}
				}
			});
		}
	};
	
	$(document).on('click', '.actions .more', function(e) {
		var $this = $('.actions .menu .active'),
			$page = $('.actions');
		
		if ($page.hasClass('open')) {
			$page.removeClass('open');
			$('body, .screen').css({'overflow': ''});
		} else {
			actionsFilling($this);
			$page.addClass('open');
			if (!isMobile()) {
				$('body, .screen').css({'overflow': 'hidden'});
			}
		} return false;
	});
	
	$(document).on('click', '.actions .menu .link', function(e) {
		var $this = $(this);
		TweenMax.set('.actions', {className: 'actions open'});
		$('.actions .menu .link').removeClass('loaded active');
		$this.addClass('active');
		actionsFilling($this);
		return false;
	});
	/*[actions] [;]*/
	
	/*[gallery] [:]*/
	$(document).on('click', '.gallery .more', function(e) {
		var urn = $(this).data('url');
		$('body, .screen').css({'overflow': 'hidden'});
		if (isThumbsLoad) {
			$('.gallery').addClass('open');
		} else {
			getPage(urn, function(data) {
				$('.gallery').addClass('open').append(data);
			});
		} return false;
	});
	$(document).on('click', '.gallery .close', function(e) {
		$('body, .screen').css({'overflow': ''});
		$('.gallery').removeClass('open');
		return false;
	});
	$(document).on('mousemove', '.gallery .prev, .gallery .next', function(e) {
		if (!isMobile()) {
			var $this = $(this),
				$item = $this.find('.pin'),
				offset = $this.offset(),
				left = offset.left,
				top = offset.top;
			
			var y = e.pageY - top,
				x = e.pageX - left;
			
			TweenMax.set($item, {
				top: y,
				left: x
			});
		} else {
			$('.gallery .manage a').css({
				top: '',
				left: ''
			});
		}
	});
	$(document).on('click', '.gallery .prev, .gallery .next, .gallery .thumbs .pin',  function(e) {
		var $this = $(this),
			urn = $this.attr('href'),
			$screen = $('.screen[data-page="gallery"]'),
			urn_fame = urn.replace(/gallery/, 'fame');
		
		$screen.data('urn', urn);
		getPage(urn, function(data) {
			var html = $(data).find('.base').html();
			$('.gallery .base').html(html);
			$('.gallery .manage a').trigger('mousemove');
			changeState('gallery', 'push', true);
			$('.gallery').removeClass('open');
		});
		
		if ($screen.data('part') === 'biathlon') {
			$('.screen[data-page="fame"]').data('urn', urn_fame);
			getPage(urn_fame, function(data) {
				$('.screen[data-page="fame"] .output').html(data);
			});
		} return false;
	});
	/*[gallery] [;]*/
	
	/*[feedback] [:]*/
	var fnResetForm = function() {
		if ($('.feedback').length) {
			var begin_data,
				begin_index,
				begin_value,
				$select = $('.topic'),
				$option_1 = $select.find('.option'),
				$option_2 = $select.find('option'),
				$value = $select.find('.value');

			if ($select.data('begin')) {
				begin_data = $select.data('begin').split(':');
				begin_index = begin_data[0];
				begin_value = begin_data[1];
				$option_2.eq(begin_index).prop('selected', true).siblings().prop('selected', false);
				$option_1.eq(begin_index).addClass('active').siblings().removeClass('active');
				$('.feedback .theme, .feedback .topic').removeClass('open');
				$value.html(begin_value);
			}
			$('.contacts, .feedback, .feedback .message').removeClass('open');
			$('.feedback').removeClass('theme-1 theme-2 theme-3 theme-4');
			formReset('#feedback');
		}
	};
	
	var fnToggleForm = function(action) {
		if (action === 'show') {
			$('.contacts').addClass('open');
			TweenMax.to('.feedback', 0.6, {
				y: '0%',
				ease: Sine.easeInOut,
				onComplete: function() {
					$('.feedback').addClass('open');
				}
			}); validateForm();
		} else {
			TweenMax.to('.feedback', 0.6, {
				y: '100%',
				ease: Sine.easeInOut,
				onComplete: function() {
					fnResetForm();
				}
			});
		}
	};
	
	var validateForm = function() {
		$('#feedback').validate({
			submitHandler: function() {
				$.ajax({
					type: 'post',
					dataType: 'json',
					data: $('#feedback').serialize(),
					url: $('#feedback').attr('action') || '#',
					beforeSend: function() {
						$('.preloader').removeClass('hide');
					},
					success: function(result) {
						var err = false;
						$.each(result, function(n, val) {
							err = true;
							$('[name="'+val+'"]').removeClass("valid");
							$('[name="'+val+'"]').addClass("error");
						});
						
						if (!err) {
							formReset('#feedback');
							$('.preloader').addClass('hide');
							$('.feedback .done').addClass('active');
							$('.feedback').addClass('show-message');
						} else {
							$('.preloader').addClass('hide');
						}
					},
					error: function() {
						$('.preloader').addClass('hide');
						$('.feedback .fall').addClass('active');
						$('.feedback').addClass('show-message');
					}
				});
			},
			errorPlacement: function(error, element) {
				return false;
			}
		});
	};
	
	if ($('#feedback').length) {
		validateForm();
	}
	
	$(document).on('click', function(e) {
		if (!isMobile()) {
			if ($('.contacts').hasClass('open')) {
				if (!$(e.target).closest('.feedback').length) {
					fnToggleForm('hide');
					return false;
				}
			}
		}
	});
	$(document).on('keydown', function(e) {
		if (e.which === 27) {
			fnToggleForm('hide');
			e.preventDefault();
		}
	});
	$(document).on('click', '.feedback .close', function(e) {
		fnToggleForm('hide');
		return false;
	});
	$(document).on('click', '.contacts .btn-form', function(e) {
		fnToggleForm('show');
		return false;
	});
	
	/*[theme][:]*/
	$(document).on('click', function(e) {
		if ($(e.target).closest('.feedback .topic').length === 0) {
			$('.feedback .theme, .feedback .topic').removeClass('open');
		}
	});
	$(document).on('click', '.feedback .topic .switch', function(e) {
		var $theme = $('.topic'),
			$option = $theme.find('.active'),
			index = $option.index(),
			value = $option.text();
		if (!$theme.data('begin')) {
			$theme.data('begin', index+':'+value);
		}
		$('.feedback .theme, .feedback .topic').toggleClass('open');
	});
	$(document).on('click', '.feedback .topic .option', function(e) {
		var $this = $(this),
			value = $this.text(),
			index = $this.index(),
			$parent = $this.parents('.topic'), 
			$select = $parent.find('select'),
			$value = $parent.find('.value');
		$('.feedback').removeClass('theme-1 theme-2 theme-3 theme-4').addClass('theme-' + (index + 1));
		$select.find('option:eq('+index+')').prop('selected', true).siblings().prop('selected', false);
		$('.feedback .theme, .feedback .topic').removeClass('open');
		$this.addClass('active').siblings().removeClass('active');
		TweenMax.to($value, 0.6, {text: value});
	});
	/*[theme][;]*/
	
	/*[feedback] [;]*/
	
	/*[pages] [;]*/
	
	/*[events] [;]*/
	$(window).on('load', function(e) {
		getSiblingsPages();
		createWelcomeGallery();
	});
	
	$(window).on('resize', function(e) {
		if (!isMobile()) {
			$('.actions .base').css({
				height: Math.max(740, $(window).height()) - 120
			});
			
			$('.story .base, .fame .some .base').css({
				minHeight: Math.max(740, $(window).height()) - 120
			});
		} else {
			$('.actions .base').css({height: ''});
			$('.story .base, .fame .some .base').css({minHeight: ''});
		}
	}).trigger('resize');
	
	$(window).on('popstate', function(e) {
		var $body = $('body'),
			urn = location.href,
			part = !1, page = !1,
			data = e.originalEvent.state,
			$first_screen = $('.screen:first'),
			$activ_screen = $('.screen.active'),
			selected_page = $activ_screen.data('page');
		
		isPopState = true;
		
		if (data) {
			part = data.part;
			page = data.page;
		} else {
			part = $body.data('part');
			page = $body.data('page');
		}
		
		if (selected_page !== page) {
			var $section = $('.welcome [data-page="'+page+'"]'),
				index = $section.index();
			
			if ($section.length) {
				$('.welcome .scene').flickity('select', index);
				if (!$first_screen.hasClass('active')) {
					animateScreen($first_screen);
				}
			} else {
				switchScreen(part, page);
			}
		} else {
			getPage(urn, function(data) {
				$('.screen[data-page="'+page+'"] .output').html(data);
			});
		}
	});
	
	$(document).on('keydown', function(e) {
		var $this = !1,
			$prev = !1,
			$next = !1,
			key = e.which;
		
		if (key in Keys) {
			if ($('.screen[data-page="fame"]').hasClass('active')) {
				if (!$('.fame').hasClass('open')) {
					$this = $('.fame .season .pin.active');
					$prev = $this.prev();
					$next = $this.next();
					
					$prev = ($prev.length) ? $prev : $('.fame .season .pin:last');
					$next = ($next.length) ? $next : $('.fame .season .pin:first');
				}
			}
			if ($('.screen[data-page="story"]').hasClass('active')) {
				$prev = $('.story .prev');
				$next = $('.story .next');
			}
			if ($('.screen[data-page="gallery"]').hasClass('active')) {
				if (!$('.gallery').hasClass('open')) {
					$prev = $('.gallery .prev');
					$next = $('.gallery .next');
				}
			}
			
			if ($prev.length && key === 37) $prev.trigger('click');
			if ($next.length && key === 39) $next.trigger('click');
			
			if (key === 38 || key === 40) {
				if (!$('.news, .fame, .actions, .gallery').hasClass('open')) {
					e.preventDefault();
					if (key === 38) {
						switchScreen(!1, !1, 'prev');
					} else 
					if (key === 40) {
						switchScreen(!1, !1, 'next');
					}
				}
			}
		}
	});
	/*[events] [;]*/
}


Share = {
	vkontakte: function(purl) {
		url  = 'http://vk.com/share.php';
		//url += '?url=' + encodeURIComponent(purl + "&_utl_t=vk");
		url += '?url=' + encodeURIComponent(purl);
		url += '&_utl_t=vk';
		Share.popup(url);
	},
	twitter: function(purl) {
		url  = 'http://twitter.com/share?';
		//url += '&?text=text';
		url += '&url=' + encodeURIComponent(purl);
		Share.popup(url); 
	},
	facebook: function(purl) {
		url  = 'https://www.facebook.com/sharer/sharer.php';
		url += '?src=sp';
		url += '&u=' + encodeURIComponent(purl);
		url += '&_utl_t=fb';
		Share.popup(url);
	},
	popup: function(url) {
		window.open(url,'','toolbar=0,status=0,width=626,height=436');
	}
};

$(function() {
	$(document).on('click', '#share_dialog a', function(e) {
		var $this = $(this);
		var share_url = $this.attr("href");

		if ($this.hasClass('vk')) {
			Share.vkontakte(share_url);
		}
		if ($this.hasClass('fb')) {
			Share.facebook(share_url);
		}
		if ($this.hasClass('tw')) {
			Share.twitter(share_url);
		}
		return false;
	});
});
